export const timeZoneNames = [
	{
		"Country code(s)": "CI, BF, GH, GM, GN, IS, ML, MR, SH, SL, SN, TG",
		"TZ identifier": "Africa/Abidjan",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "africa",
		Notes: "",
	},
	{
		"Country code(s)": "GH",
		"TZ identifier": "Africa/Accra",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "backward",
		Notes: "Link to Africa/Abidjan",
	},
	{
		"Country code(s)": "ET",
		"TZ identifier": "Africa/Addis_Ababa",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EAT",
		"Source file": "backward",
		Notes: "Link to Africa/Nairobi",
	},
	{
		"Country code(s)": "DZ",
		"TZ identifier": "Africa/Algiers",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "CET",
		"Source file": "africa",
		Notes: "",
	},
	{
		"Country code(s)": "ER",
		"TZ identifier": "Africa/Asmara",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EAT",
		"Source file": "backward",
		Notes: "Link to Africa/Nairobi",
	},
	{
		"Country code(s)": "ER",
		"TZ identifier": "Africa/Asmera",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EAT",
		"Source file": "backward",
		Notes: "Link to Africa/Nairobi",
	},
	{
		"Country code(s)": "ML",
		"TZ identifier": "Africa/Bamako",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "backward",
		Notes: "Link to Africa/Abidjan",
	},
	{
		"Country code(s)": "CF",
		"TZ identifier": "Africa/Bangui",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "WAT",
		"Source file": "backward",
		Notes: "Link to Africa/Lagos",
	},
	{
		"Country code(s)": "GM",
		"TZ identifier": "Africa/Banjul",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "backward",
		Notes: "Link to Africa/Abidjan",
	},
	{
		"Country code(s)": "GW",
		"TZ identifier": "Africa/Bissau",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "africa",
		Notes: "",
	},
	{
		"Country code(s)": "MW",
		"TZ identifier": "Africa/Blantyre",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CAT",
		"Source file": "backward",
		Notes: "Link to Africa/Maputo",
	},
	{
		"Country code(s)": "CG",
		"TZ identifier": "Africa/Brazzaville",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "WAT",
		"Source file": "backward",
		Notes: "Link to Africa/Lagos",
	},
	{
		"Country code(s)": "BI",
		"TZ identifier": "Africa/Bujumbura",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CAT",
		"Source file": "backward",
		Notes: "Link to Africa/Maputo",
	},
	{
		"Country code(s)": "EG",
		"TZ identifier": "Africa/Cairo",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "africa",
	},
	{
		"Country code(s)": "MA",
		"TZ identifier": "Africa/Casablanca",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "+01",
		"Source file": "+00",
		Notes: "africa",
	},
	{
		"Country code(s)": "ES",
		"TZ identifier": "Africa/Ceuta",
		"Embedded comments": "Ceuta, Melilla",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "GN",
		"TZ identifier": "Africa/Conakry",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "backward",
		Notes: "Link to Africa/Abidjan",
	},
	{
		"Country code(s)": "SN",
		"TZ identifier": "Africa/Dakar",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "backward",
		Notes: "Link to Africa/Abidjan",
	},
	{
		"Country code(s)": "TZ",
		"TZ identifier": "Africa/Dar_es_Salaam",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EAT",
		"Source file": "backward",
		Notes: "Link to Africa/Nairobi",
	},
	{
		"Country code(s)": "DJ",
		"TZ identifier": "Africa/Djibouti",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EAT",
		"Source file": "backward",
		Notes: "Link to Africa/Nairobi",
	},
	{
		"Country code(s)": "CM",
		"TZ identifier": "Africa/Douala",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "WAT",
		"Source file": "backward",
		Notes: "Link to Africa/Lagos",
	},
	{
		"Country code(s)": "EH",
		"TZ identifier": "Africa/El_Aaiun",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "+01",
		"Source file": "+00",
		Notes: "africa",
	},
	{
		"Country code(s)": "SL",
		"TZ identifier": "Africa/Freetown",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "backward",
		Notes: "Link to Africa/Abidjan",
	},
	{
		"Country code(s)": "BW",
		"TZ identifier": "Africa/Gaborone",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CAT",
		"Source file": "backward",
		Notes: "Link to Africa/Maputo",
	},
	{
		"Country code(s)": "ZW",
		"TZ identifier": "Africa/Harare",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CAT",
		"Source file": "backward",
		Notes: "Link to Africa/Maputo",
	},
	{
		"Country code(s)": "ZA, LS, SZ",
		"TZ identifier": "Africa/Johannesburg",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "SAST",
		"Source file": "africa",
		Notes: "",
	},
	{
		"Country code(s)": "SS",
		"TZ identifier": "Africa/Juba",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CAT",
		"Source file": "africa",
		Notes: "",
	},
	{
		"Country code(s)": "UG",
		"TZ identifier": "Africa/Kampala",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EAT",
		"Source file": "backward",
		Notes: "Link to Africa/Nairobi",
	},
	{
		"Country code(s)": "SD",
		"TZ identifier": "Africa/Khartoum",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CAT",
		"Source file": "africa",
		Notes: "",
	},
	{
		"Country code(s)": "RW",
		"TZ identifier": "Africa/Kigali",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CAT",
		"Source file": "backward",
		Notes: "Link to Africa/Maputo",
	},
	{
		"Country code(s)": "CD",
		"TZ identifier": "Africa/Kinshasa",
		"Embedded comments": "Dem. Rep. of Congo (west)",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "WAT",
		"Source file": "backward",
		Notes: "Link to Africa/Lagos",
	},
	{
		"Country code(s)": "NG, AO, BJ, CD, CF, CG, CM, GA, GQ, NE",
		"TZ identifier": "Africa/Lagos",
		"Embedded comments": "West Africa Time",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "WAT",
		"Source file": "africa",
		Notes: "",
	},
	{
		"Country code(s)": "GA",
		"TZ identifier": "Africa/Libreville",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "WAT",
		"Source file": "backward",
		Notes: "Link to Africa/Lagos",
	},
	{
		"Country code(s)": "TG",
		"TZ identifier": "Africa/Lome",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "backward",
		Notes: "Link to Africa/Abidjan",
	},
	{
		"Country code(s)": "AO",
		"TZ identifier": "Africa/Luanda",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "WAT",
		"Source file": "backward",
		Notes: "Link to Africa/Lagos",
	},
	{
		"Country code(s)": "CD",
		"TZ identifier": "Africa/Lubumbashi",
		"Embedded comments": "Dem. Rep. of Congo (east)",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CAT",
		"Source file": "backward",
		Notes: "Link to Africa/Maputo",
	},
	{
		"Country code(s)": "ZM",
		"TZ identifier": "Africa/Lusaka",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CAT",
		"Source file": "backward",
		Notes: "Link to Africa/Maputo",
	},
	{
		"Country code(s)": "GQ",
		"TZ identifier": "Africa/Malabo",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "WAT",
		"Source file": "backward",
		Notes: "Link to Africa/Lagos",
	},
	{
		"Country code(s)": "MZ, BI, BW, CD, MW, RW, ZM, ZW",
		"TZ identifier": "Africa/Maputo",
		"Embedded comments": "Central Africa Time",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CAT",
		"Source file": "africa",
		Notes: "",
	},
	{
		"Country code(s)": "LS",
		"TZ identifier": "Africa/Maseru",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "SAST",
		"Source file": "backward",
		Notes: "Link to Africa/Johannesburg",
	},
	{
		"Country code(s)": "SZ",
		"TZ identifier": "Africa/Mbabane",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "SAST",
		"Source file": "backward",
		Notes: "Link to Africa/Johannesburg",
	},
	{
		"Country code(s)": "SO",
		"TZ identifier": "Africa/Mogadishu",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EAT",
		"Source file": "backward",
		Notes: "Link to Africa/Nairobi",
	},
	{
		"Country code(s)": "LR",
		"TZ identifier": "Africa/Monrovia",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "africa",
		Notes: "",
	},
	{
		"Country code(s)": "KE, DJ, ER, ET, KM, MG, SO, TZ, UG, YT",
		"TZ identifier": "Africa/Nairobi",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EAT",
		"Source file": "africa",
		Notes: "",
	},
	{
		"Country code(s)": "TD",
		"TZ identifier": "Africa/Ndjamena",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "WAT",
		"Source file": "africa",
		Notes: "",
	},
	{
		"Country code(s)": "NE",
		"TZ identifier": "Africa/Niamey",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "WAT",
		"Source file": "backward",
		Notes: "Link to Africa/Lagos",
	},
	{
		"Country code(s)": "MR",
		"TZ identifier": "Africa/Nouakchott",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "backward",
		Notes: "Link to Africa/Abidjan",
	},
	{
		"Country code(s)": "BF",
		"TZ identifier": "Africa/Ouagadougou",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "backward",
		Notes: "Link to Africa/Abidjan",
	},
	{
		"Country code(s)": "BJ",
		"TZ identifier": "Africa/Porto-Novo",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "WAT",
		"Source file": "backward",
		Notes: "Link to Africa/Lagos",
	},
	{
		"Country code(s)": "ST",
		"TZ identifier": "Africa/Sao_Tome",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "africa",
		Notes: "",
	},
	{
		"Country code(s)": "ML",
		"TZ identifier": "Africa/Timbuktu",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "backward",
		Notes: "Link to Africa/Abidjan",
	},
	{
		"Country code(s)": "LY",
		"TZ identifier": "Africa/Tripoli",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "EET",
		"Source file": "africa",
		Notes: "",
	},
	{
		"Country code(s)": "TN",
		"TZ identifier": "Africa/Tunis",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "CET",
		"Source file": "africa",
		Notes: "",
	},
	{
		"Country code(s)": "NA",
		"TZ identifier": "Africa/Windhoek",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CAT",
		"Source file": "africa",
		Notes: "",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Adak",
		"Embedded comments": "Alaska - western Aleutians",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-10:00",
		"UTC offset±hh:mm DST": "-09:00",
		"Time zone abbreviation": "HST",
		"Source file": "HDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Anchorage",
		"Embedded comments": "Alaska (most areas)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-09:00",
		"UTC offset±hh:mm DST": "-08:00",
		"Time zone abbreviation": "AKST",
		"Source file": "AKDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "AI",
		"TZ identifier": "America/Anguilla",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "backward",
		Notes: "Link to America/Puerto_Rico",
	},
	{
		"Country code(s)": "AG",
		"TZ identifier": "America/Antigua",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "backward",
		Notes: "Link to America/Puerto_Rico",
	},
	{
		"Country code(s)": "BR",
		"TZ identifier": "America/Araguaina",
		"Embedded comments": "Tocantins",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "AR",
		"TZ identifier": "America/Argentina/Buenos_Aires",
		"Embedded comments": "Buenos Aires (BA, CF)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "AR",
		"TZ identifier": "America/Argentina/Catamarca",
		"Embedded comments": "Catamarca (CT), Chubut (CH)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "AR",
		"TZ identifier": "America/Argentina/ComodRivadavia",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "backward",
		Notes: "Link to America/Argentina/Catamarca",
	},
	{
		"Country code(s)": "AR",
		"TZ identifier": "America/Argentina/Cordoba",
		"Embedded comments": "most areas: CB, CC, CN, ER, FM, MN, SE, SF",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "AR",
		"TZ identifier": "America/Argentina/Jujuy",
		"Embedded comments": "Jujuy (JY)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "AR",
		"TZ identifier": "America/Argentina/La_Rioja",
		"Embedded comments": "La Rioja (LR)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "AR",
		"TZ identifier": "America/Argentina/Mendoza",
		"Embedded comments": "Mendoza (MZ)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "AR",
		"TZ identifier": "America/Argentina/Rio_Gallegos",
		"Embedded comments": "Santa Cruz (SC)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "AR",
		"TZ identifier": "America/Argentina/Salta",
		"Embedded comments": "Salta (SA, LP, NQ, RN)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "AR",
		"TZ identifier": "America/Argentina/San_Juan",
		"Embedded comments": "San Juan (SJ)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "AR",
		"TZ identifier": "America/Argentina/San_Luis",
		"Embedded comments": "San Luis (SL)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "AR",
		"TZ identifier": "America/Argentina/Tucuman",
		"Embedded comments": "Tucumán (TM)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "AR",
		"TZ identifier": "America/Argentina/Ushuaia",
		"Embedded comments": "Tierra del Fuego (TF)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "AW",
		"TZ identifier": "America/Aruba",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "backward",
		Notes: "Link to America/Puerto_Rico",
	},
	{
		"Country code(s)": "PY",
		"TZ identifier": "America/Asuncion",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-04",
		"Source file": "-03",
		Notes: "southamerica",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Atikokan",
		"Embedded comments": "EST - ON (Atikokan), NU (Coral H)",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "EST",
		"Source file": "backward",
		Notes: "Link to America/Panama",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Atka",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-10:00",
		"UTC offset±hh:mm DST": "-09:00",
		"Time zone abbreviation": "HST",
		"Source file": "HDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "BR",
		"TZ identifier": "America/Bahia",
		"Embedded comments": "Bahia",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "MX",
		"TZ identifier": "America/Bahia_Banderas",
		"Embedded comments": "Bahía de Banderas",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "CST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "BB",
		"TZ identifier": "America/Barbados",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "BR",
		"TZ identifier": "America/Belem",
		"Embedded comments": "Pará (east), Amapá",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "BZ",
		"TZ identifier": "America/Belize",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "CST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Blanc-Sablon",
		"Embedded comments": "AST - QC (Lower North Shore)",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "backward",
		Notes: "Link to America/Puerto_Rico",
	},
	{
		"Country code(s)": "BR",
		"TZ identifier": "America/Boa_Vista",
		"Embedded comments": "Roraima",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "-04",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "CO",
		"TZ identifier": "America/Bogota",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "-05",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Boise",
		"Embedded comments": "Mountain - ID (south), OR (east)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "MST",
		"Source file": "MDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "AR",
		"TZ identifier": "America/Buenos_Aires",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "backward",
		Notes: "Link to America/Argentina/Buenos_Aires",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Cambridge_Bay",
		"Embedded comments": "Mountain - NU (west)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "MST",
		"Source file": "MDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "BR",
		"TZ identifier": "America/Campo_Grande",
		"Embedded comments": "Mato Grosso do Sul",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "-04",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "MX",
		"TZ identifier": "America/Cancun",
		"Embedded comments": "Quintana Roo",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "EST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "VE",
		"TZ identifier": "America/Caracas",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "-04",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "AR",
		"TZ identifier": "America/Catamarca",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "backward",
		Notes: "Link to America/Argentina/Catamarca",
	},
	{
		"Country code(s)": "GF",
		"TZ identifier": "America/Cayenne",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "KY",
		"TZ identifier": "America/Cayman",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "EST",
		"Source file": "backward",
		Notes: "Link to America/Panama",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Chicago",
		"Embedded comments": "Central (most areas)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "CST",
		"Source file": "CDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "MX",
		"TZ identifier": "America/Chihuahua",
		"Embedded comments": "Chihuahua (most areas)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "CST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "MX",
		"TZ identifier": "America/Ciudad_Juarez",
		"Embedded comments": "Chihuahua (US border - west)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "MST",
		"Source file": "MDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Coral_Harbour",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "EST",
		"Source file": "backward",
		Notes: "Link to America/Panama",
	},
	{
		"Country code(s)": "AR",
		"TZ identifier": "America/Cordoba",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "backward",
		Notes: "Link to America/Argentina/Cordoba",
	},
	{
		"Country code(s)": "CR",
		"TZ identifier": "America/Costa_Rica",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "CST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Creston",
		"Embedded comments": "MST - BC (Creston)",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-07:00",
		"Time zone abbreviation": "MST",
		"Source file": "backward",
		Notes: "Link to America/Phoenix",
	},
	{
		"Country code(s)": "BR",
		"TZ identifier": "America/Cuiaba",
		"Embedded comments": "Mato Grosso",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "-04",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "CW",
		"TZ identifier": "America/Curacao",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "backward",
		Notes: "Link to America/Puerto_Rico",
	},
	{
		"Country code(s)": "GL",
		"TZ identifier": "America/Danmarkshavn",
		"Embedded comments": "National Park (east coast)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Dawson",
		"Embedded comments": "MST - Yukon (west)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-07:00",
		"Time zone abbreviation": "MST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Dawson_Creek",
		"Embedded comments": "MST - BC (Dawson Cr, Ft St John)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-07:00",
		"Time zone abbreviation": "MST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Denver",
		"Embedded comments": "Mountain (most areas)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "MST",
		"Source file": "MDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Detroit",
		"Embedded comments": "Eastern - MI (most areas)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "DM",
		"TZ identifier": "America/Dominica",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "backward",
		Notes: "Link to America/Puerto_Rico",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Edmonton",
		"Embedded comments": "Mountain - AB, BC(E), NT(E), SK(W)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "MST",
		"Source file": "MDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "BR",
		"TZ identifier": "America/Eirunepe",
		"Embedded comments": "Amazonas (west)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "-05",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "SV",
		"TZ identifier": "America/El_Salvador",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "CST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "MX",
		"TZ identifier": "America/Ensenada",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-08:00",
		"UTC offset±hh:mm DST": "-07:00",
		"Time zone abbreviation": "PST",
		"Source file": "PDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Fort_Nelson",
		"Embedded comments": "MST - BC (Ft Nelson)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-07:00",
		"Time zone abbreviation": "MST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Fort_Wayne",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "BR",
		"TZ identifier": "America/Fortaleza",
		"Embedded comments": "Brazil (northeast: MA, PI, CE, RN, PB)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Glace_Bay",
		"Embedded comments": "Atlantic - NS (Cape Breton)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "AST",
		"Source file": "ADT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "GL",
		"TZ identifier": "America/Godthab",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-02:00",
		"UTC offset±hh:mm DST": "-01:00",
		"Time zone abbreviation": "-02",
		"Source file": "-01",
		Notes: "backward",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Goose_Bay",
		"Embedded comments": "Atlantic - Labrador (most areas)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "AST",
		"Source file": "ADT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "TC",
		"TZ identifier": "America/Grand_Turk",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "GD",
		"TZ identifier": "America/Grenada",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "backward",
		Notes: "Link to America/Puerto_Rico",
	},
	{
		"Country code(s)": "GP",
		"TZ identifier": "America/Guadeloupe",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "backward",
		Notes: "Link to America/Puerto_Rico",
	},
	{
		"Country code(s)": "GT",
		"TZ identifier": "America/Guatemala",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "CST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "EC",
		"TZ identifier": "America/Guayaquil",
		"Embedded comments": "Ecuador (mainland)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "-05",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "GY",
		"TZ identifier": "America/Guyana",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "-04",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Halifax",
		"Embedded comments": "Atlantic - NS (most areas), PE",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "AST",
		"Source file": "ADT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "CU",
		"TZ identifier": "America/Havana",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "CST",
		"Source file": "CDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "MX",
		"TZ identifier": "America/Hermosillo",
		"Embedded comments": "Sonora",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-07:00",
		"Time zone abbreviation": "MST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Indiana/Indianapolis",
		"Embedded comments": "Eastern - IN (most areas)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Indiana/Knox",
		"Embedded comments": "Central - IN (Starke)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "CST",
		"Source file": "CDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Indiana/Marengo",
		"Embedded comments": "Eastern - IN (Crawford)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Indiana/Petersburg",
		"Embedded comments": "Eastern - IN (Pike)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Indiana/Tell_City",
		"Embedded comments": "Central - IN (Perry)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "CST",
		"Source file": "CDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Indiana/Vevay",
		"Embedded comments": "Eastern - IN (Switzerland)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Indiana/Vincennes",
		"Embedded comments": "Eastern - IN (Da, Du, K, Mn)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Indiana/Winamac",
		"Embedded comments": "Eastern - IN (Pulaski)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Indianapolis",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Inuvik",
		"Embedded comments": "Mountain - NT (west)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "MST",
		"Source file": "MDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Iqaluit",
		"Embedded comments": "Eastern - NU (most areas)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "JM",
		"TZ identifier": "America/Jamaica",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "EST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "AR",
		"TZ identifier": "America/Jujuy",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "backward",
		Notes: "Link to America/Argentina/Jujuy",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Juneau",
		"Embedded comments": "Alaska - Juneau area",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-09:00",
		"UTC offset±hh:mm DST": "-08:00",
		"Time zone abbreviation": "AKST",
		"Source file": "AKDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Kentucky/Louisville",
		"Embedded comments": "Eastern - KY (Louisville area)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Kentucky/Monticello",
		"Embedded comments": "Eastern - KY (Wayne)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Knox_IN",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "CST",
		"Source file": "CDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "BQ",
		"TZ identifier": "America/Kralendijk",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "backward",
		Notes: "Link to America/Puerto_Rico",
	},
	{
		"Country code(s)": "BO",
		"TZ identifier": "America/La_Paz",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "-04",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "PE",
		"TZ identifier": "America/Lima",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "-05",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Los_Angeles",
		"Embedded comments": "Pacific",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-08:00",
		"UTC offset±hh:mm DST": "-07:00",
		"Time zone abbreviation": "PST",
		"Source file": "PDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Louisville",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "SX",
		"TZ identifier": "America/Lower_Princes",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "backward",
		Notes: "Link to America/Puerto_Rico",
	},
	{
		"Country code(s)": "BR",
		"TZ identifier": "America/Maceio",
		"Embedded comments": "Alagoas, Sergipe",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "NI",
		"TZ identifier": "America/Managua",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "CST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "BR",
		"TZ identifier": "America/Manaus",
		"Embedded comments": "Amazonas (east)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "-04",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "MF",
		"TZ identifier": "America/Marigot",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "backward",
		Notes: "Link to America/Puerto_Rico",
	},
	{
		"Country code(s)": "MQ",
		"TZ identifier": "America/Martinique",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "MX",
		"TZ identifier": "America/Matamoros",
		"Embedded comments": "Coahuila, Nuevo León, Tamaulipas (US border)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "CST",
		"Source file": "CDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "MX",
		"TZ identifier": "America/Mazatlan",
		"Embedded comments": "Baja California Sur, Nayarit (most areas), Sinaloa",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-07:00",
		"Time zone abbreviation": "MST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "AR",
		"TZ identifier": "America/Mendoza",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "backward",
		Notes: "Link to America/Argentina/Mendoza",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Menominee",
		"Embedded comments": "Central - MI (Wisconsin border)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "CST",
		"Source file": "CDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "MX",
		"TZ identifier": "America/Merida",
		"Embedded comments": "Campeche, Yucatán",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "CST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Metlakatla",
		"Embedded comments": "Alaska - Annette Island",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-09:00",
		"UTC offset±hh:mm DST": "-08:00",
		"Time zone abbreviation": "AKST",
		"Source file": "AKDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "MX",
		"TZ identifier": "America/Mexico_City",
		"Embedded comments": "Central Mexico",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "CST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "PM",
		"TZ identifier": "America/Miquelon",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-02:00",
		"Time zone abbreviation": "-03",
		"Source file": "-02",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Moncton",
		"Embedded comments": "Atlantic - New Brunswick",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "AST",
		"Source file": "ADT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "MX",
		"TZ identifier": "America/Monterrey",
		"Embedded comments":
			"Durango; Coahuila, Nuevo León, Tamaulipas (most areas)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "CST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "UY",
		"TZ identifier": "America/Montevideo",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Montreal",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "MS",
		"TZ identifier": "America/Montserrat",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "backward",
		Notes: "Link to America/Puerto_Rico",
	},
	{
		"Country code(s)": "BS",
		"TZ identifier": "America/Nassau",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/New_York",
		"Embedded comments": "Eastern (most areas)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Nipigon",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Nome",
		"Embedded comments": "Alaska (west)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-09:00",
		"UTC offset±hh:mm DST": "-08:00",
		"Time zone abbreviation": "AKST",
		"Source file": "AKDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "BR",
		"TZ identifier": "America/Noronha",
		"Embedded comments": "Atlantic islands",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-02:00",
		"UTC offset±hh:mm DST": "-02:00",
		"Time zone abbreviation": "-02",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/North_Dakota/Beulah",
		"Embedded comments": "Central - ND (Mercer)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "CST",
		"Source file": "CDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/North_Dakota/Center",
		"Embedded comments": "Central - ND (Oliver)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "CST",
		"Source file": "CDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/North_Dakota/New_Salem",
		"Embedded comments": "Central - ND (Morton rural)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "CST",
		"Source file": "CDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "GL",
		"TZ identifier": "America/Nuuk",
		"Embedded comments": "most of Greenland",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-02:00",
		"UTC offset±hh:mm DST": "-01:00",
		"Time zone abbreviation": "-02",
		"Source file": "-01",
		Notes: "europe",
	},
	{
		"Country code(s)": "MX",
		"TZ identifier": "America/Ojinaga",
		"Embedded comments": "Chihuahua (US border - east)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "CST",
		"Source file": "CDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "PA, CA, KY",
		"TZ identifier": "America/Panama",
		"Embedded comments": "EST - ON (Atikokan), NU (Coral H)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "EST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Pangnirtung",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "SR",
		"TZ identifier": "America/Paramaribo",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "US, CA",
		"TZ identifier": "America/Phoenix",
		"Embedded comments": "MST - AZ (most areas), Creston BC",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-07:00",
		"Time zone abbreviation": "MST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "HT",
		"TZ identifier": "America/Port-au-Prince",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "TT",
		"TZ identifier": "America/Port_of_Spain",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "backward",
		Notes: "Link to America/Puerto_Rico",
	},
	{
		"Country code(s)": "BR",
		"TZ identifier": "America/Porto_Acre",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "-05",
		"Source file": "backward",
		Notes: "Link to America/Rio_Branco",
	},
	{
		"Country code(s)": "BR",
		"TZ identifier": "America/Porto_Velho",
		"Embedded comments": "Rondônia",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "-04",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)":
			"PR, AG, CA, AI, AW, BL, BQ, CW, DM, GD, GP, KN, LC, MF, MS, SX, TT, VC, VG, VI",
		"TZ identifier": "America/Puerto_Rico",
		"Embedded comments": "AST - QC (Lower North Shore)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "CL",
		"TZ identifier": "America/Punta_Arenas",
		"Embedded comments": "Region of Magallanes",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "Magallanes Region",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Rainy_River",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "CST",
		"Source file": "CDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Rankin_Inlet",
		"Embedded comments": "Central - NU (central)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "CST",
		"Source file": "CDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "BR",
		"TZ identifier": "America/Recife",
		"Embedded comments": "Pernambuco",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Regina",
		"Embedded comments": "CST - SK (most areas)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "CST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Resolute",
		"Embedded comments": "Central - NU (Resolute)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "CST",
		"Source file": "CDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "BR",
		"TZ identifier": "America/Rio_Branco",
		"Embedded comments": "Acre",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "-05",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "AR",
		"TZ identifier": "America/Rosario",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "backward",
		Notes: "Link to America/Argentina/Cordoba",
	},
	{
		"Country code(s)": "MX",
		"TZ identifier": "America/Santa_Isabel",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-08:00",
		"UTC offset±hh:mm DST": "-07:00",
		"Time zone abbreviation": "PST",
		"Source file": "PDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "BR",
		"TZ identifier": "America/Santarem",
		"Embedded comments": "Pará (west)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "CL",
		"TZ identifier": "America/Santiago",
		"Embedded comments": "most of Chile",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-04",
		"Source file": "-03",
		Notes: "southamerica",
	},
	{
		"Country code(s)": "DO",
		"TZ identifier": "America/Santo_Domingo",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "BR",
		"TZ identifier": "America/Sao_Paulo",
		"Embedded comments":
			"Brazil (southeast: GO, DF, MG, ES, RJ, SP, PR, SC, RS)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "GL",
		"TZ identifier": "America/Scoresbysund",
		"Embedded comments": "Scoresbysund/Ittoqqortoormiit",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-02:00",
		"UTC offset±hh:mm DST": "-01:00",
		"Time zone abbreviation": "-02",
		"Source file": "-01",
		Notes: "europe",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Shiprock",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "MST",
		"Source file": "MDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Sitka",
		"Embedded comments": "Alaska - Sitka area",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-09:00",
		"UTC offset±hh:mm DST": "-08:00",
		"Time zone abbreviation": "AKST",
		"Source file": "AKDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "BL",
		"TZ identifier": "America/St_Barthelemy",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "backward",
		Notes: "Link to America/Puerto_Rico",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/St_Johns",
		"Embedded comments": "Newfoundland, Labrador (SE)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:30",
		"UTC offset±hh:mm DST": "-02:30",
		"Time zone abbreviation": "NST",
		"Source file": "NDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "KN",
		"TZ identifier": "America/St_Kitts",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "backward",
		Notes: "Link to America/Puerto_Rico",
	},
	{
		"Country code(s)": "LC",
		"TZ identifier": "America/St_Lucia",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "backward",
		Notes: "Link to America/Puerto_Rico",
	},
	{
		"Country code(s)": "VI",
		"TZ identifier": "America/St_Thomas",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "backward",
		Notes: "Link to America/Puerto_Rico",
	},
	{
		"Country code(s)": "VC",
		"TZ identifier": "America/St_Vincent",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "backward",
		Notes: "Link to America/Puerto_Rico",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Swift_Current",
		"Embedded comments": "CST - SK (midwest)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "CST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "HN",
		"TZ identifier": "America/Tegucigalpa",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "CST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "GL",
		"TZ identifier": "America/Thule",
		"Embedded comments": "Thule/Pituffik",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "AST",
		"Source file": "ADT",
		Notes: "europe",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Thunder_Bay",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "MX",
		"TZ identifier": "America/Tijuana",
		"Embedded comments": "Baja California",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-08:00",
		"UTC offset±hh:mm DST": "-07:00",
		"Time zone abbreviation": "PST",
		"Source file": "PDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "CA, BS",
		"TZ identifier": "America/Toronto",
		"Embedded comments": "Eastern - ON & QC (most areas)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "VG",
		"TZ identifier": "America/Tortola",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "backward",
		Notes: "Link to America/Puerto_Rico",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Vancouver",
		"Embedded comments": "Pacific - BC (most areas)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-08:00",
		"UTC offset±hh:mm DST": "-07:00",
		"Time zone abbreviation": "PST",
		"Source file": "PDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "VI",
		"TZ identifier": "America/Virgin",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "AST",
		"Source file": "backward",
		Notes: "Link to America/Puerto_Rico",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Whitehorse",
		"Embedded comments": "MST - Yukon (east)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-07:00",
		"Time zone abbreviation": "MST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Winnipeg",
		"Embedded comments": "Central - ON (west), Manitoba",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "CST",
		"Source file": "CDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "America/Yakutat",
		"Embedded comments": "Alaska - Yakutat",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-09:00",
		"UTC offset±hh:mm DST": "-08:00",
		"Time zone abbreviation": "AKST",
		"Source file": "AKDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "America/Yellowknife",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "MST",
		"Source file": "MDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "AQ",
		"TZ identifier": "Antarctica/Casey",
		"Embedded comments": "Casey",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "+08",
		"Source file": "antarctica",
		Notes: "",
	},
	{
		"Country code(s)": "AQ",
		"TZ identifier": "Antarctica/Davis",
		"Embedded comments": "Davis",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+07:00",
		"UTC offset±hh:mm DST": "+07:00",
		"Time zone abbreviation": "+07",
		"Source file": "antarctica",
		Notes: "",
	},
	{
		"Country code(s)": "AQ",
		"TZ identifier": "Antarctica/DumontDUrville",
		"Embedded comments": "Dumont-d'Urville",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+10:00",
		"Time zone abbreviation": "+10",
		"Source file": "backward",
		Notes: "Link to Pacific/Port_Moresby",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Antarctica/Macquarie",
		"Embedded comments": "Macquarie Island",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "AEST",
		"Source file": "AEDT",
		Notes: "australasia",
	},
	{
		"Country code(s)": "AQ",
		"TZ identifier": "Antarctica/Mawson",
		"Embedded comments": "Mawson",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+05:00",
		"UTC offset±hh:mm DST": "+05:00",
		"Time zone abbreviation": "+05",
		"Source file": "antarctica",
		Notes: "",
	},
	{
		"Country code(s)": "AQ",
		"TZ identifier": "Antarctica/McMurdo",
		"Embedded comments": "New Zealand time - McMurdo, South Pole",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+12:00",
		"UTC offset±hh:mm DST": "+13:00",
		"Time zone abbreviation": "NZST",
		"Source file": "NZDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "AQ",
		"TZ identifier": "Antarctica/Palmer",
		"Embedded comments": "Palmer",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "Chilean Antarctica Region",
	},
	{
		"Country code(s)": "AQ",
		"TZ identifier": "Antarctica/Rothera",
		"Embedded comments": "Rothera",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "antarctica",
		Notes: "",
	},
	{
		"Country code(s)": "AQ",
		"TZ identifier": "Antarctica/South_Pole",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+12:00",
		"UTC offset±hh:mm DST": "+13:00",
		"Time zone abbreviation": "NZST",
		"Source file": "NZDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "AQ",
		"TZ identifier": "Antarctica/Syowa",
		"Embedded comments": "Syowa",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "+03",
		"Source file": "backward",
		Notes: "Link to Asia/Riyadh",
	},
	{
		"Country code(s)": "AQ",
		"TZ identifier": "Antarctica/Troll",
		"Embedded comments": "Troll",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "+00",
		"Source file": "+02",
		Notes: "antarctica",
	},
	{
		"Country code(s)": "AQ",
		"TZ identifier": "Antarctica/Vostok",
		"Embedded comments": "Vostok",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+05:00",
		"UTC offset±hh:mm DST": "+05:00",
		"Time zone abbreviation": "+05",
		"Source file": "antarctica",
		Notes: "",
	},
	{
		"Country code(s)": "SJ",
		"TZ identifier": "Arctic/Longyearbyen",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "YE",
		"TZ identifier": "Asia/Aden",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "+03",
		"Source file": "backward",
		Notes: "Link to Asia/Riyadh",
	},
	{
		"Country code(s)": "KZ",
		"TZ identifier": "Asia/Almaty",
		"Embedded comments": "most of Kazakhstan",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+05:00",
		"UTC offset±hh:mm DST": "+05:00",
		"Time zone abbreviation": "+05",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "JO",
		"TZ identifier": "Asia/Amman",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "+03",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Asia/Anadyr",
		"Embedded comments": "MSK+09 - Bering Sea",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+12:00",
		"UTC offset±hh:mm DST": "+12:00",
		"Time zone abbreviation": "+12",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "KZ",
		"TZ identifier": "Asia/Aqtau",
		"Embedded comments": "Mangghystaū/Mankistau",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+05:00",
		"UTC offset±hh:mm DST": "+05:00",
		"Time zone abbreviation": "+05",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "KZ",
		"TZ identifier": "Asia/Aqtobe",
		"Embedded comments": "Aqtöbe/Aktobe",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+05:00",
		"UTC offset±hh:mm DST": "+05:00",
		"Time zone abbreviation": "+05",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "TM",
		"TZ identifier": "Asia/Ashgabat",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+05:00",
		"UTC offset±hh:mm DST": "+05:00",
		"Time zone abbreviation": "+05",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "TM",
		"TZ identifier": "Asia/Ashkhabad",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+05:00",
		"UTC offset±hh:mm DST": "+05:00",
		"Time zone abbreviation": "+05",
		"Source file": "backward",
		Notes: "Link to Asia/Ashgabat",
	},
	{
		"Country code(s)": "KZ",
		"TZ identifier": "Asia/Atyrau",
		"Embedded comments": "Atyraū/Atirau/Gur'yev",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+05:00",
		"UTC offset±hh:mm DST": "+05:00",
		"Time zone abbreviation": "+05",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "IQ",
		"TZ identifier": "Asia/Baghdad",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "+03",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "BH",
		"TZ identifier": "Asia/Bahrain",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "+03",
		"Source file": "backward",
		Notes: "Link to Asia/Qatar",
	},
	{
		"Country code(s)": "AZ",
		"TZ identifier": "Asia/Baku",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+04:00",
		"UTC offset±hh:mm DST": "+04:00",
		"Time zone abbreviation": "+04",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "TH, CX, KH, LA, VN",
		"TZ identifier": "Asia/Bangkok",
		"Embedded comments": "north Vietnam",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+07:00",
		"UTC offset±hh:mm DST": "+07:00",
		"Time zone abbreviation": "+07",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Asia/Barnaul",
		"Embedded comments": "MSK+04 - Altai",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+07:00",
		"UTC offset±hh:mm DST": "+07:00",
		"Time zone abbreviation": "+07",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "LB",
		"TZ identifier": "Asia/Beirut",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "asia",
	},
	{
		"Country code(s)": "KG",
		"TZ identifier": "Asia/Bishkek",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+06:00",
		"UTC offset±hh:mm DST": "+06:00",
		"Time zone abbreviation": "+06",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "BN",
		"TZ identifier": "Asia/Brunei",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "+08",
		"Source file": "backward",
		Notes: "Link to Asia/Kuching",
	},
	{
		"Country code(s)": "IN",
		"TZ identifier": "Asia/Calcutta",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+05:30",
		"UTC offset±hh:mm DST": "+05:30",
		"Time zone abbreviation": "IST",
		"Source file": "backward",
		Notes: "Link to Asia/Kolkata",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Asia/Chita",
		"Embedded comments": "MSK+06 - Zabaykalsky",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+09:00",
		"UTC offset±hh:mm DST": "+09:00",
		"Time zone abbreviation": "+09",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "MN",
		"TZ identifier": "Asia/Choibalsan",
		"Embedded comments": "Dornod, Sükhbaatar",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "+08",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "CN",
		"TZ identifier": "Asia/Chongqing",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "CST",
		"Source file": "backward",
		Notes: "Link to Asia/Shanghai",
	},
	{
		"Country code(s)": "CN",
		"TZ identifier": "Asia/Chungking",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "CST",
		"Source file": "backward",
		Notes: "Link to Asia/Shanghai",
	},
	{
		"Country code(s)": "LK",
		"TZ identifier": "Asia/Colombo",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+05:30",
		"UTC offset±hh:mm DST": "+05:30",
		"Time zone abbreviation": "+0530",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "BD",
		"TZ identifier": "Asia/Dacca",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+06:00",
		"UTC offset±hh:mm DST": "+06:00",
		"Time zone abbreviation": "+06",
		"Source file": "backward",
		Notes: "Link to Asia/Dhaka",
	},
	{
		"Country code(s)": "SY",
		"TZ identifier": "Asia/Damascus",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "+03",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "BD",
		"TZ identifier": "Asia/Dhaka",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+06:00",
		"UTC offset±hh:mm DST": "+06:00",
		"Time zone abbreviation": "+06",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "TL",
		"TZ identifier": "Asia/Dili",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+09:00",
		"UTC offset±hh:mm DST": "+09:00",
		"Time zone abbreviation": "+09",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "AE, OM, RE, SC, TF",
		"TZ identifier": "Asia/Dubai",
		"Embedded comments": "Crozet",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+04:00",
		"UTC offset±hh:mm DST": "+04:00",
		"Time zone abbreviation": "+04",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "TJ",
		"TZ identifier": "Asia/Dushanbe",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+05:00",
		"UTC offset±hh:mm DST": "+05:00",
		"Time zone abbreviation": "+05",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "CY",
		"TZ identifier": "Asia/Famagusta",
		"Embedded comments": "Northern Cyprus",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "asia",
	},
	{
		"Country code(s)": "PS",
		"TZ identifier": "Asia/Gaza",
		"Embedded comments": "Gaza Strip",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "asia",
	},
	{
		"Country code(s)": "CN",
		"TZ identifier": "Asia/Harbin",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "CST",
		"Source file": "backward",
		Notes: "Link to Asia/Shanghai",
	},
	{
		"Country code(s)": "PS",
		"TZ identifier": "Asia/Hebron",
		"Embedded comments": "West Bank",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "asia",
	},
	{
		"Country code(s)": "VN",
		"TZ identifier": "Asia/Ho_Chi_Minh",
		"Embedded comments": "south Vietnam",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+07:00",
		"UTC offset±hh:mm DST": "+07:00",
		"Time zone abbreviation": "+07",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "HK",
		"TZ identifier": "Asia/Hong_Kong",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "HKT",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "MN",
		"TZ identifier": "Asia/Hovd",
		"Embedded comments": "Bayan-Ölgii, Govi-Altai, Hovd, Uvs, Zavkhan",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+07:00",
		"UTC offset±hh:mm DST": "+07:00",
		"Time zone abbreviation": "+07",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Asia/Irkutsk",
		"Embedded comments": "MSK+05 - Irkutsk, Buryatia",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "+08",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "TR",
		"TZ identifier": "Asia/Istanbul",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "+03",
		"Source file": "backward",
		Notes: "Link to Europe/Istanbul",
	},
	{
		"Country code(s)": "ID",
		"TZ identifier": "Asia/Jakarta",
		"Embedded comments": "Java, Sumatra",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+07:00",
		"UTC offset±hh:mm DST": "+07:00",
		"Time zone abbreviation": "WIB",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "ID",
		"TZ identifier": "Asia/Jayapura",
		"Embedded comments":
			"New Guinea (West Papua / Irian Jaya), Malukus/Moluccas",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+09:00",
		"UTC offset±hh:mm DST": "+09:00",
		"Time zone abbreviation": "WIT",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "IL",
		"TZ identifier": "Asia/Jerusalem",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "IST",
		"Source file": "IDT",
		Notes: "asia",
	},
	{
		"Country code(s)": "AF",
		"TZ identifier": "Asia/Kabul",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+04:30",
		"UTC offset±hh:mm DST": "+04:30",
		"Time zone abbreviation": "+0430",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Asia/Kamchatka",
		"Embedded comments": "MSK+09 - Kamchatka",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+12:00",
		"UTC offset±hh:mm DST": "+12:00",
		"Time zone abbreviation": "+12",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "PK",
		"TZ identifier": "Asia/Karachi",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+05:00",
		"UTC offset±hh:mm DST": "+05:00",
		"Time zone abbreviation": "PKT",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "CN",
		"TZ identifier": "Asia/Kashgar",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+06:00",
		"UTC offset±hh:mm DST": "+06:00",
		"Time zone abbreviation": "+06",
		"Source file": "backward",
		Notes: "Link to Asia/Urumqi[note 1]",
	},
	{
		"Country code(s)": "NP",
		"TZ identifier": "Asia/Kathmandu",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+05:45",
		"UTC offset±hh:mm DST": "+05:45",
		"Time zone abbreviation": "+0545",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "NP",
		"TZ identifier": "Asia/Katmandu",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+05:45",
		"UTC offset±hh:mm DST": "+05:45",
		"Time zone abbreviation": "+0545",
		"Source file": "backward",
		Notes: "Link to Asia/Kathmandu",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Asia/Khandyga",
		"Embedded comments": "MSK+06 - Tomponsky, Ust-Maysky",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+09:00",
		"UTC offset±hh:mm DST": "+09:00",
		"Time zone abbreviation": "+09",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "IN",
		"TZ identifier": "Asia/Kolkata",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+05:30",
		"UTC offset±hh:mm DST": "+05:30",
		"Time zone abbreviation": "IST",
		"Source file": "asia",
		Notes: "Note: Different zones in history, see Time in India.",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Asia/Krasnoyarsk",
		"Embedded comments": "MSK+04 - Krasnoyarsk area",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+07:00",
		"UTC offset±hh:mm DST": "+07:00",
		"Time zone abbreviation": "+07",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "MY",
		"TZ identifier": "Asia/Kuala_Lumpur",
		"Embedded comments": "Malaysia (peninsula)",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "+08",
		"Source file": "backward",
		Notes: "Link to Asia/Singapore",
	},
	{
		"Country code(s)": "MY, BN",
		"TZ identifier": "Asia/Kuching",
		"Embedded comments": "Sabah, Sarawak",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "+08",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "KW",
		"TZ identifier": "Asia/Kuwait",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "+03",
		"Source file": "backward",
		Notes: "Link to Asia/Riyadh",
	},
	{
		"Country code(s)": "MO",
		"TZ identifier": "Asia/Macao",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "CST",
		"Source file": "backward",
		Notes: "Link to Asia/Macau",
	},
	{
		"Country code(s)": "MO",
		"TZ identifier": "Asia/Macau",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "CST",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Asia/Magadan",
		"Embedded comments": "MSK+08 - Magadan",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+11:00",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "+11",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "ID",
		"TZ identifier": "Asia/Makassar",
		"Embedded comments":
			"Borneo (east, south), Sulawesi/Celebes, Bali, Nusa Tengarra, Timor (west)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "WITA",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "PH",
		"TZ identifier": "Asia/Manila",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "PST",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "OM",
		"TZ identifier": "Asia/Muscat",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+04:00",
		"UTC offset±hh:mm DST": "+04:00",
		"Time zone abbreviation": "+04",
		"Source file": "backward",
		Notes: "Link to Asia/Dubai",
	},
	{
		"Country code(s)": "CY",
		"TZ identifier": "Asia/Nicosia",
		"Embedded comments": "most of Cyprus",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "asia",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Asia/Novokuznetsk",
		"Embedded comments": "MSK+04 - Kemerovo",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+07:00",
		"UTC offset±hh:mm DST": "+07:00",
		"Time zone abbreviation": "+07",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Asia/Novosibirsk",
		"Embedded comments": "MSK+04 - Novosibirsk",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+07:00",
		"UTC offset±hh:mm DST": "+07:00",
		"Time zone abbreviation": "+07",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Asia/Omsk",
		"Embedded comments": "MSK+03 - Omsk",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+06:00",
		"UTC offset±hh:mm DST": "+06:00",
		"Time zone abbreviation": "+06",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "KZ",
		"TZ identifier": "Asia/Oral",
		"Embedded comments": "West Kazakhstan",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+05:00",
		"UTC offset±hh:mm DST": "+05:00",
		"Time zone abbreviation": "+05",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "KH",
		"TZ identifier": "Asia/Phnom_Penh",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+07:00",
		"UTC offset±hh:mm DST": "+07:00",
		"Time zone abbreviation": "+07",
		"Source file": "backward",
		Notes: "Link to Asia/Bangkok",
	},
	{
		"Country code(s)": "ID",
		"TZ identifier": "Asia/Pontianak",
		"Embedded comments": "Borneo (west, central)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+07:00",
		"UTC offset±hh:mm DST": "+07:00",
		"Time zone abbreviation": "WIB",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "KP",
		"TZ identifier": "Asia/Pyongyang",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+09:00",
		"UTC offset±hh:mm DST": "+09:00",
		"Time zone abbreviation": "KST",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "QA, BH",
		"TZ identifier": "Asia/Qatar",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "+03",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "KZ",
		"TZ identifier": "Asia/Qostanay",
		"Embedded comments": "Qostanay/Kostanay/Kustanay",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+05:00",
		"UTC offset±hh:mm DST": "+05:00",
		"Time zone abbreviation": "+05",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "KZ",
		"TZ identifier": "Asia/Qyzylorda",
		"Embedded comments": "Qyzylorda/Kyzylorda/Kzyl-Orda",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+05:00",
		"UTC offset±hh:mm DST": "+05:00",
		"Time zone abbreviation": "+05",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "MM",
		"TZ identifier": "Asia/Rangoon",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+06:30",
		"UTC offset±hh:mm DST": "+06:30",
		"Time zone abbreviation": "+0630",
		"Source file": "backward",
		Notes: "Link to Asia/Yangon",
	},
	{
		"Country code(s)": "SA, AQ, KW, YE",
		"TZ identifier": "Asia/Riyadh",
		"Embedded comments": "Syowa",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "+03",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "VN",
		"TZ identifier": "Asia/Saigon",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+07:00",
		"UTC offset±hh:mm DST": "+07:00",
		"Time zone abbreviation": "+07",
		"Source file": "backward",
		Notes: "Link to Asia/Ho_Chi_Minh",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Asia/Sakhalin",
		"Embedded comments": "MSK+08 - Sakhalin Island",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+11:00",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "+11",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "UZ",
		"TZ identifier": "Asia/Samarkand",
		"Embedded comments": "Uzbekistan (west)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+05:00",
		"UTC offset±hh:mm DST": "+05:00",
		"Time zone abbreviation": "+05",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "KR",
		"TZ identifier": "Asia/Seoul",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+09:00",
		"UTC offset±hh:mm DST": "+09:00",
		"Time zone abbreviation": "KST",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "CN",
		"TZ identifier": "Asia/Shanghai",
		"Embedded comments": "Beijing Time",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "CST",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "SG, MY",
		"TZ identifier": "Asia/Singapore",
		"Embedded comments": "peninsular Malaysia",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "+08",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Asia/Srednekolymsk",
		"Embedded comments": "MSK+08 - Sakha (E), N Kuril Is",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+11:00",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "+11",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "TW",
		"TZ identifier": "Asia/Taipei",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "CST",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "UZ",
		"TZ identifier": "Asia/Tashkent",
		"Embedded comments": "Uzbekistan (east)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+05:00",
		"UTC offset±hh:mm DST": "+05:00",
		"Time zone abbreviation": "+05",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "GE",
		"TZ identifier": "Asia/Tbilisi",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+04:00",
		"UTC offset±hh:mm DST": "+04:00",
		"Time zone abbreviation": "+04",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "IR",
		"TZ identifier": "Asia/Tehran",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+03:30",
		"UTC offset±hh:mm DST": "+03:30",
		"Time zone abbreviation": "+0330",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "IL",
		"TZ identifier": "Asia/Tel_Aviv",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "IST",
		"Source file": "IDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "BT",
		"TZ identifier": "Asia/Thimbu",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+06:00",
		"UTC offset±hh:mm DST": "+06:00",
		"Time zone abbreviation": "+06",
		"Source file": "backward",
		Notes: "Link to Asia/Thimphu",
	},
	{
		"Country code(s)": "BT",
		"TZ identifier": "Asia/Thimphu",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+06:00",
		"UTC offset±hh:mm DST": "+06:00",
		"Time zone abbreviation": "+06",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "JP",
		"TZ identifier": "Asia/Tokyo",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+09:00",
		"UTC offset±hh:mm DST": "+09:00",
		"Time zone abbreviation": "JST",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Asia/Tomsk",
		"Embedded comments": "MSK+04 - Tomsk",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+07:00",
		"UTC offset±hh:mm DST": "+07:00",
		"Time zone abbreviation": "+07",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "ID",
		"TZ identifier": "Asia/Ujung_Pandang",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "WITA",
		"Source file": "backward",
		Notes: "Link to Asia/Makassar",
	},
	{
		"Country code(s)": "MN",
		"TZ identifier": "Asia/Ulaanbaatar",
		"Embedded comments": "most of Mongolia",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "+08",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "MN",
		"TZ identifier": "Asia/Ulan_Bator",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "+08",
		"Source file": "backward",
		Notes: "Link to Asia/Ulaanbaatar",
	},
	{
		"Country code(s)": "CN",
		"TZ identifier": "Asia/Urumqi",
		"Embedded comments": "Xinjiang Time",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+06:00",
		"UTC offset±hh:mm DST": "+06:00",
		"Time zone abbreviation": "+06",
		"Source file": "asia",
		Notes:
			"The Asia/Urumqi entry in the tz database reflected the use of Xinjiang Time by part of the local population. Consider using Asia/Shanghai for Beijing Time if that is preferred.",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Asia/Ust-Nera",
		"Embedded comments": "MSK+07 - Oymyakonsky",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+10:00",
		"Time zone abbreviation": "+10",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "LA",
		"TZ identifier": "Asia/Vientiane",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+07:00",
		"UTC offset±hh:mm DST": "+07:00",
		"Time zone abbreviation": "+07",
		"Source file": "backward",
		Notes: "Link to Asia/Bangkok",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Asia/Vladivostok",
		"Embedded comments": "MSK+07 - Amur River",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+10:00",
		"Time zone abbreviation": "+10",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Asia/Yakutsk",
		"Embedded comments": "MSK+06 - Lena River",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+09:00",
		"UTC offset±hh:mm DST": "+09:00",
		"Time zone abbreviation": "+09",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "MM, CC",
		"TZ identifier": "Asia/Yangon",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+06:30",
		"UTC offset±hh:mm DST": "+06:30",
		"Time zone abbreviation": "+0630",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Asia/Yekaterinburg",
		"Embedded comments": "MSK+02 - Urals",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+05:00",
		"UTC offset±hh:mm DST": "+05:00",
		"Time zone abbreviation": "+05",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "AM",
		"TZ identifier": "Asia/Yerevan",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+04:00",
		"UTC offset±hh:mm DST": "+04:00",
		"Time zone abbreviation": "+04",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "PT",
		"TZ identifier": "Atlantic/Azores",
		"Embedded comments": "Azores",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-01:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "-01",
		"Source file": "+00",
		Notes: "europe",
	},
	{
		"Country code(s)": "BM",
		"TZ identifier": "Atlantic/Bermuda",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "AST",
		"Source file": "ADT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "ES",
		"TZ identifier": "Atlantic/Canary",
		"Embedded comments": "Canary Islands",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "WET",
		"Source file": "WEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "CV",
		"TZ identifier": "Atlantic/Cape_Verde",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-01:00",
		"UTC offset±hh:mm DST": "-01:00",
		"Time zone abbreviation": "-01",
		"Source file": "africa",
		Notes: "",
	},
	{
		"Country code(s)": "FO",
		"TZ identifier": "Atlantic/Faeroe",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "WET",
		"Source file": "WEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "FO",
		"TZ identifier": "Atlantic/Faroe",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "WET",
		"Source file": "WEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "SJ",
		"TZ identifier": "Atlantic/Jan_Mayen",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "PT",
		"TZ identifier": "Atlantic/Madeira",
		"Embedded comments": "Madeira Islands",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "WET",
		"Source file": "WEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "IS",
		"TZ identifier": "Atlantic/Reykjavik",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "backward",
		Notes: "Link to Africa/Abidjan",
	},
	{
		"Country code(s)": "GS",
		"TZ identifier": "Atlantic/South_Georgia",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-02:00",
		"UTC offset±hh:mm DST": "-02:00",
		"Time zone abbreviation": "-02",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "SH",
		"TZ identifier": "Atlantic/St_Helena",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "backward",
		Notes: "Link to Africa/Abidjan",
	},
	{
		"Country code(s)": "FK",
		"TZ identifier": "Atlantic/Stanley",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/ACT",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "AEST",
		"Source file": "AEDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/Adelaide",
		"Embedded comments": "South Australia",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+09:30",
		"UTC offset±hh:mm DST": "+10:30",
		"Time zone abbreviation": "ACST",
		"Source file": "ACDT",
		Notes: "australasia",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/Brisbane",
		"Embedded comments": "Queensland (most areas)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+10:00",
		"Time zone abbreviation": "AEST",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/Broken_Hill",
		"Embedded comments": "New South Wales (Yancowinna)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+09:30",
		"UTC offset±hh:mm DST": "+10:30",
		"Time zone abbreviation": "ACST",
		"Source file": "ACDT",
		Notes: "australasia",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/Canberra",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "AEST",
		"Source file": "AEDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/Currie",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "AEST",
		"Source file": "AEDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/Darwin",
		"Embedded comments": "Northern Territory",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+09:30",
		"UTC offset±hh:mm DST": "+09:30",
		"Time zone abbreviation": "ACST",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/Eucla",
		"Embedded comments": "Western Australia (Eucla)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+08:45",
		"UTC offset±hh:mm DST": "+08:45",
		"Time zone abbreviation": "+0845",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/Hobart",
		"Embedded comments": "Tasmania",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "AEST",
		"Source file": "AEDT",
		Notes: "australasia",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/LHI",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+10:30",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "+1030",
		"Source file": "+11",
		Notes: "backward",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/Lindeman",
		"Embedded comments": "Queensland (Whitsunday Islands)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+10:00",
		"Time zone abbreviation": "AEST",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/Lord_Howe",
		"Embedded comments": "Lord Howe Island",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+10:30",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "+1030",
		"Source file": "+11",
		Notes: "australasia",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/Melbourne",
		"Embedded comments": "Victoria",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "AEST",
		"Source file": "AEDT",
		Notes: "australasia",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/North",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+09:30",
		"UTC offset±hh:mm DST": "+09:30",
		"Time zone abbreviation": "ACST",
		"Source file": "backward",
		Notes: "Link to Australia/Darwin",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/NSW",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "AEST",
		"Source file": "AEDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/Perth",
		"Embedded comments": "Western Australia (most areas)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "AWST",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/Queensland",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+10:00",
		"Time zone abbreviation": "AEST",
		"Source file": "backward",
		Notes: "Link to Australia/Brisbane",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/South",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+09:30",
		"UTC offset±hh:mm DST": "+10:30",
		"Time zone abbreviation": "ACST",
		"Source file": "ACDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/Sydney",
		"Embedded comments": "New South Wales (most areas)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "AEST",
		"Source file": "AEDT",
		Notes: "australasia",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/Tasmania",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "AEST",
		"Source file": "AEDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/Victoria",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "AEST",
		"Source file": "AEDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/West",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "AWST",
		"Source file": "backward",
		Notes: "Link to Australia/Perth",
	},
	{
		"Country code(s)": "AU",
		"TZ identifier": "Australia/Yancowinna",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+09:30",
		"UTC offset±hh:mm DST": "+10:30",
		"Time zone abbreviation": "ACST",
		"Source file": "ACDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "BR",
		"TZ identifier": "Brazil/Acre",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "-05",
		"Source file": "backward",
		Notes: "Link to America/Rio_Branco",
	},
	{
		"Country code(s)": "BR",
		"TZ identifier": "Brazil/DeNoronha",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-02:00",
		"UTC offset±hh:mm DST": "-02:00",
		"Time zone abbreviation": "-02",
		"Source file": "backward",
		Notes: "Link to America/Noronha",
	},
	{
		"Country code(s)": "BR",
		"TZ identifier": "Brazil/East",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "backward",
		Notes: "Link to America/Sao_Paulo",
	},
	{
		"Country code(s)": "BR",
		"TZ identifier": "Brazil/West",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "-04",
		"Source file": "backward",
		Notes: "Link to America/Manaus",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "Canada/Atlantic",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "AST",
		"Source file": "ADT",
		Notes: "backward",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "Canada/Central",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "CST",
		"Source file": "CDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "Canada/Eastern",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "Canada/Mountain",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "MST",
		"Source file": "MDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "Canada/Newfoundland",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-03:30",
		"UTC offset±hh:mm DST": "-02:30",
		"Time zone abbreviation": "NST",
		"Source file": "NDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "Canada/Pacific",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-08:00",
		"UTC offset±hh:mm DST": "-07:00",
		"Time zone abbreviation": "PST",
		"Source file": "PDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "Canada/Saskatchewan",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "CST",
		"Source file": "backward",
		Notes: "Link to America/Regina",
	},
	{
		"Country code(s)": "CA",
		"TZ identifier": "Canada/Yukon",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-07:00",
		"Time zone abbreviation": "MST",
		"Source file": "backward",
		Notes: "Link to America/Whitehorse",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "CET",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "CL",
		"TZ identifier": "Chile/Continental",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-04",
		"Source file": "-03",
		Notes: "backward",
	},
	{
		"Country code(s)": "CL",
		"TZ identifier": "Chile/EasterIsland",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "-06",
		"Source file": "-05",
		Notes: "backward",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "CST6CDT",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "CST",
		"Source file": "CDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "CU",
		"TZ identifier": "Cuba",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "CST",
		"Source file": "CDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "EET",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "EG",
		"TZ identifier": "Egypt",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "IE",
		"TZ identifier": "Eire",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "GMT",
		"Source file": "IST",
		Notes: "backward",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "EST",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "EST",
		"Source file": "northamerica",
		Notes:
			"Choose a zone that currently observes EST without daylight saving time, such as America/Cancun.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "EST5EDT",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "etcetera",
		Notes: "",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT+0",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "backward",
		Notes: "Link to Etc/GMT",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT+1",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-01:00",
		"UTC offset±hh:mm DST": "-01:00",
		"Time zone abbreviation": "-01",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT+10",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-10:00",
		"UTC offset±hh:mm DST": "-10:00",
		"Time zone abbreviation": -10,
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT+11",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-11:00",
		"UTC offset±hh:mm DST": "-11:00",
		"Time zone abbreviation": -11,
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT+12",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-12:00",
		"UTC offset±hh:mm DST": "-12:00",
		"Time zone abbreviation": -12,
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT+2",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-02:00",
		"UTC offset±hh:mm DST": "-02:00",
		"Time zone abbreviation": "-02",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT+3",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-03:00",
		"UTC offset±hh:mm DST": "-03:00",
		"Time zone abbreviation": "-03",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT+4",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-04:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "-04",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT+5",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "-05",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT+6",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "-06",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT+7",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-07:00",
		"Time zone abbreviation": "-07",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT+8",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-08:00",
		"UTC offset±hh:mm DST": "-08:00",
		"Time zone abbreviation": "-08",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT+9",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-09:00",
		"UTC offset±hh:mm DST": "-09:00",
		"Time zone abbreviation": "-09",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT-0",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "backward",
		Notes: "Link to Etc/GMT",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT-1",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "+01",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT-10",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+10:00",
		"Time zone abbreviation": "+10",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT-11",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+11:00",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "+11",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT-12",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+12:00",
		"UTC offset±hh:mm DST": "+12:00",
		"Time zone abbreviation": "+12",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT-13",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+13:00",
		"UTC offset±hh:mm DST": "+13:00",
		"Time zone abbreviation": "+13",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT-14",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+14:00",
		"UTC offset±hh:mm DST": "+14:00",
		"Time zone abbreviation": "+14",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT-2",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "+02",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT-3",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "+03",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT-4",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+04:00",
		"UTC offset±hh:mm DST": "+04:00",
		"Time zone abbreviation": "+04",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT-5",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+05:00",
		"UTC offset±hh:mm DST": "+05:00",
		"Time zone abbreviation": "+05",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT-6",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+06:00",
		"UTC offset±hh:mm DST": "+06:00",
		"Time zone abbreviation": "+06",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT-7",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+07:00",
		"UTC offset±hh:mm DST": "+07:00",
		"Time zone abbreviation": "+07",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT-8",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "+08",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT-9",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+09:00",
		"UTC offset±hh:mm DST": "+09:00",
		"Time zone abbreviation": "+09",
		"Source file": "etcetera",
		Notes: "Sign is intentionally inverted. See the Etc area description.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/GMT0",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "backward",
		Notes: "Link to Etc/GMT",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/Greenwich",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "backward",
		Notes: "Link to Etc/GMT",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/UCT",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "UTC",
		"Source file": "backward",
		Notes: "Link to Etc/UTC",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/Universal",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "UTC",
		"Source file": "backward",
		Notes: "Link to Etc/UTC",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/UTC",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "UTC",
		"Source file": "etcetera",
		Notes: "",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Etc/Zulu",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "UTC",
		"Source file": "backward",
		Notes: "Link to Etc/UTC",
	},
	{
		"Country code(s)": "NL",
		"TZ identifier": "Europe/Amsterdam",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "AD",
		"TZ identifier": "Europe/Andorra",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Europe/Astrakhan",
		"Embedded comments": "MSK+01 - Astrakhan",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+04:00",
		"UTC offset±hh:mm DST": "+04:00",
		"Time zone abbreviation": "+04",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "GR",
		"TZ identifier": "Europe/Athens",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "GB",
		"TZ identifier": "Europe/Belfast",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "GMT",
		"Source file": "BST",
		Notes: "backward",
	},
	{
		"Country code(s)": "RS, BA, HR, ME, MK, SI",
		"TZ identifier": "Europe/Belgrade",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "DE, DK, NO, SE, SJ",
		"TZ identifier": "Europe/Berlin",
		"Embedded comments": "most of Germany",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "SK",
		"TZ identifier": "Europe/Bratislava",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "BE, LU, NL",
		"TZ identifier": "Europe/Brussels",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "RO",
		"TZ identifier": "Europe/Bucharest",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "HU",
		"TZ identifier": "Europe/Budapest",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "DE",
		"TZ identifier": "Europe/Busingen",
		"Embedded comments": "Busingen",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "MD",
		"TZ identifier": "Europe/Chisinau",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "DK",
		"TZ identifier": "Europe/Copenhagen",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "IE",
		"TZ identifier": "Europe/Dublin",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "GMT",
		"Source file": "IST",
		Notes: "europe",
	},
	{
		"Country code(s)": "GI",
		"TZ identifier": "Europe/Gibraltar",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "GG",
		"TZ identifier": "Europe/Guernsey",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "GMT",
		"Source file": "BST",
		Notes: "backward",
	},
	{
		"Country code(s)": "FI, AX",
		"TZ identifier": "Europe/Helsinki",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "IM",
		"TZ identifier": "Europe/Isle_of_Man",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "GMT",
		"Source file": "BST",
		Notes: "backward",
	},
	{
		"Country code(s)": "TR",
		"TZ identifier": "Europe/Istanbul",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "+03",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "JE",
		"TZ identifier": "Europe/Jersey",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "GMT",
		"Source file": "BST",
		Notes: "backward",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Europe/Kaliningrad",
		"Embedded comments": "MSK-01 - Kaliningrad",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "EET",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "UA",
		"TZ identifier": "Europe/Kiev",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Europe/Kirov",
		"Embedded comments": "MSK+00 - Kirov",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "MSK",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "UA",
		"TZ identifier": "Europe/Kyiv",
		"Embedded comments": "most of Ukraine",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "PT",
		"TZ identifier": "Europe/Lisbon",
		"Embedded comments": "Portugal (mainland)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "WET",
		"Source file": "WEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "SI",
		"TZ identifier": "Europe/Ljubljana",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "GB, GG, IM, JE",
		"TZ identifier": "Europe/London",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "GMT",
		"Source file": "BST",
		Notes: "europe",
	},
	{
		"Country code(s)": "LU",
		"TZ identifier": "Europe/Luxembourg",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "ES",
		"TZ identifier": "Europe/Madrid",
		"Embedded comments": "Spain (mainland)",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "MT",
		"TZ identifier": "Europe/Malta",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "AX",
		"TZ identifier": "Europe/Mariehamn",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "BY",
		"TZ identifier": "Europe/Minsk",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "+03",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "MC",
		"TZ identifier": "Europe/Monaco",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Europe/Moscow",
		"Embedded comments": "MSK+00 - Moscow area",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "MSK",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "CY",
		"TZ identifier": "Europe/Nicosia",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "NO",
		"TZ identifier": "Europe/Oslo",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "FR, MC",
		"TZ identifier": "Europe/Paris",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "ME",
		"TZ identifier": "Europe/Podgorica",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "CZ, SK",
		"TZ identifier": "Europe/Prague",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "LV",
		"TZ identifier": "Europe/Riga",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "IT, SM, VA",
		"TZ identifier": "Europe/Rome",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Europe/Samara",
		"Embedded comments": "MSK+01 - Samara, Udmurtia",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+04:00",
		"UTC offset±hh:mm DST": "+04:00",
		"Time zone abbreviation": "+04",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "SM",
		"TZ identifier": "Europe/San_Marino",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "BA",
		"TZ identifier": "Europe/Sarajevo",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Europe/Saratov",
		"Embedded comments": "MSK+01 - Saratov",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+04:00",
		"UTC offset±hh:mm DST": "+04:00",
		"Time zone abbreviation": "+04",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "RU, UA",
		"TZ identifier": "Europe/Simferopol",
		"Embedded comments": "Crimea",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "MSK",
		"Source file": "europe",
		Notes: "Disputed - Reflects data in the TZDB.[note 2]",
	},
	{
		"Country code(s)": "MK",
		"TZ identifier": "Europe/Skopje",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "BG",
		"TZ identifier": "Europe/Sofia",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "SE",
		"TZ identifier": "Europe/Stockholm",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "EE",
		"TZ identifier": "Europe/Tallinn",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "AL",
		"TZ identifier": "Europe/Tirane",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "MD",
		"TZ identifier": "Europe/Tiraspol",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Europe/Ulyanovsk",
		"Embedded comments": "MSK+01 - Ulyanovsk",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+04:00",
		"UTC offset±hh:mm DST": "+04:00",
		"Time zone abbreviation": "+04",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "UA",
		"TZ identifier": "Europe/Uzhgorod",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "LI",
		"TZ identifier": "Europe/Vaduz",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "VA",
		"TZ identifier": "Europe/Vatican",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "AT",
		"TZ identifier": "Europe/Vienna",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "LT",
		"TZ identifier": "Europe/Vilnius",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "Europe/Volgograd",
		"Embedded comments": "MSK+00 - Volgograd",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "MSK",
		"Source file": "europe",
		Notes: "",
	},
	{
		"Country code(s)": "PL",
		"TZ identifier": "Europe/Warsaw",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "HR",
		"TZ identifier": "Europe/Zagreb",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "UA",
		"TZ identifier": "Europe/Zaporozhye",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EET",
		"Source file": "EEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "CH, DE, LI",
		"TZ identifier": "Europe/Zurich",
		"Embedded comments": "Büsingen",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Factory",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "-00",
		"Source file": "factory",
		Notes: "",
	},
	{
		"Country code(s)": "GB",
		"TZ identifier": "GB",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "GMT",
		"Source file": "BST",
		Notes: "backward",
	},
	{
		"Country code(s)": "GB",
		"TZ identifier": "GB-Eire",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "GMT",
		"Source file": "BST",
		Notes: "backward",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "GMT",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "etcetera",
		Notes: "Link to Etc/GMT",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "GMT+0",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "backward",
		Notes: "Link to Etc/GMT",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "GMT-0",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "backward",
		Notes: "Link to Etc/GMT",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "GMT0",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "backward",
		Notes: "Link to Etc/GMT",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Greenwich",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "backward",
		Notes: "Link to Etc/GMT",
	},
	{
		"Country code(s)": "HK",
		"TZ identifier": "Hongkong",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "HKT",
		"Source file": "backward",
		Notes: "Link to Asia/Hong_Kong",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "HST",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-10:00",
		"UTC offset±hh:mm DST": "-10:00",
		"Time zone abbreviation": "HST",
		"Source file": "northamerica",
		Notes:
			"Choose a zone that currently observes HST without daylight saving time, such as Pacific/Honolulu.",
	},
	{
		"Country code(s)": "IS",
		"TZ identifier": "Iceland",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "GMT",
		"Source file": "backward",
		Notes: "Link to Africa/Abidjan",
	},
	{
		"Country code(s)": "MG",
		"TZ identifier": "Indian/Antananarivo",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EAT",
		"Source file": "backward",
		Notes: "Link to Africa/Nairobi",
	},
	{
		"Country code(s)": "IO",
		"TZ identifier": "Indian/Chagos",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+06:00",
		"UTC offset±hh:mm DST": "+06:00",
		"Time zone abbreviation": "+06",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "CX",
		"TZ identifier": "Indian/Christmas",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+07:00",
		"UTC offset±hh:mm DST": "+07:00",
		"Time zone abbreviation": "+07",
		"Source file": "backward",
		Notes: "Link to Asia/Bangkok",
	},
	{
		"Country code(s)": "CC",
		"TZ identifier": "Indian/Cocos",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+06:30",
		"UTC offset±hh:mm DST": "+06:30",
		"Time zone abbreviation": "+0630",
		"Source file": "backward",
		Notes: "Link to Asia/Yangon",
	},
	{
		"Country code(s)": "KM",
		"TZ identifier": "Indian/Comoro",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EAT",
		"Source file": "backward",
		Notes: "Link to Africa/Nairobi",
	},
	{
		"Country code(s)": "TF",
		"TZ identifier": "Indian/Kerguelen",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+05:00",
		"UTC offset±hh:mm DST": "+05:00",
		"Time zone abbreviation": "+05",
		"Source file": "backward",
		Notes: "Link to Indian/Maldives",
	},
	{
		"Country code(s)": "SC",
		"TZ identifier": "Indian/Mahe",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+04:00",
		"UTC offset±hh:mm DST": "+04:00",
		"Time zone abbreviation": "+04",
		"Source file": "backward",
		Notes: "Link to Asia/Dubai",
	},
	{
		"Country code(s)": "MV, TF",
		"TZ identifier": "Indian/Maldives",
		"Embedded comments": "Kerguelen, St Paul I, Amsterdam I",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+05:00",
		"UTC offset±hh:mm DST": "+05:00",
		"Time zone abbreviation": "+05",
		"Source file": "asia",
		Notes: "",
	},
	{
		"Country code(s)": "MU",
		"TZ identifier": "Indian/Mauritius",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+04:00",
		"UTC offset±hh:mm DST": "+04:00",
		"Time zone abbreviation": "+04",
		"Source file": "africa",
		Notes: "",
	},
	{
		"Country code(s)": "YT",
		"TZ identifier": "Indian/Mayotte",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "EAT",
		"Source file": "backward",
		Notes: "Link to Africa/Nairobi",
	},
	{
		"Country code(s)": "RE",
		"TZ identifier": "Indian/Reunion",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+04:00",
		"UTC offset±hh:mm DST": "+04:00",
		"Time zone abbreviation": "+04",
		"Source file": "backward",
		Notes: "Link to Asia/Dubai",
	},
	{
		"Country code(s)": "IR",
		"TZ identifier": "Iran",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+03:30",
		"UTC offset±hh:mm DST": "+03:30",
		"Time zone abbreviation": "+0330",
		"Source file": "backward",
		Notes: "Link to Asia/Tehran",
	},
	{
		"Country code(s)": "IL",
		"TZ identifier": "Israel",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "IST",
		"Source file": "IDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "JM",
		"TZ identifier": "Jamaica",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "EST",
		"Source file": "backward",
		Notes: "Link to America/Jamaica",
	},
	{
		"Country code(s)": "JP",
		"TZ identifier": "Japan",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+09:00",
		"UTC offset±hh:mm DST": "+09:00",
		"Time zone abbreviation": "JST",
		"Source file": "backward",
		Notes: "Link to Asia/Tokyo",
	},
	{
		"Country code(s)": "MH",
		"TZ identifier": "Kwajalein",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+12:00",
		"UTC offset±hh:mm DST": "+12:00",
		"Time zone abbreviation": "+12",
		"Source file": "backward",
		Notes: "Link to Pacific/Kwajalein",
	},
	{
		"Country code(s)": "LY",
		"TZ identifier": "Libya",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+02:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "EET",
		"Source file": "backward",
		Notes: "Link to Africa/Tripoli",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "MET",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "MET",
		"Source file": "MEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "MX",
		"TZ identifier": "Mexico/BajaNorte",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-08:00",
		"UTC offset±hh:mm DST": "-07:00",
		"Time zone abbreviation": "PST",
		"Source file": "PDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "MX",
		"TZ identifier": "Mexico/BajaSur",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-07:00",
		"Time zone abbreviation": "MST",
		"Source file": "backward",
		Notes: "Link to America/Mazatlan",
	},
	{
		"Country code(s)": "MX",
		"TZ identifier": "Mexico/General",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "CST",
		"Source file": "backward",
		Notes: "Link to America/Mexico_City",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "MST",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-07:00",
		"Time zone abbreviation": "MST",
		"Source file": "northamerica",
		Notes:
			"Choose a zone that currently observes MST without daylight saving time, such as America/Phoenix.",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "MST7MDT",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "MST",
		"Source file": "MDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "Navajo",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "MST",
		"Source file": "MDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "NZ",
		"TZ identifier": "NZ",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+12:00",
		"UTC offset±hh:mm DST": "+13:00",
		"Time zone abbreviation": "NZST",
		"Source file": "NZDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "NZ",
		"TZ identifier": "NZ-CHAT",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+12:45",
		"UTC offset±hh:mm DST": "+13:45",
		"Time zone abbreviation": "+1245",
		"Source file": "+1345",
		Notes: "backward",
	},
	{
		"Country code(s)": "WS",
		"TZ identifier": "Pacific/Apia",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+13:00",
		"UTC offset±hh:mm DST": "+13:00",
		"Time zone abbreviation": "+13",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "NZ, AQ",
		"TZ identifier": "Pacific/Auckland",
		"Embedded comments": "New Zealand time",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+12:00",
		"UTC offset±hh:mm DST": "+13:00",
		"Time zone abbreviation": "NZST",
		"Source file": "NZDT",
		Notes: "australasia",
	},
	{
		"Country code(s)": "PG",
		"TZ identifier": "Pacific/Bougainville",
		"Embedded comments": "Bougainville",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+11:00",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "+11",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "NZ",
		"TZ identifier": "Pacific/Chatham",
		"Embedded comments": "Chatham Islands",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+12:45",
		"UTC offset±hh:mm DST": "+13:45",
		"Time zone abbreviation": "+1245",
		"Source file": "+1345",
		Notes: "australasia",
	},
	{
		"Country code(s)": "FM",
		"TZ identifier": "Pacific/Chuuk",
		"Embedded comments": "Chuuk/Truk, Yap",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+10:00",
		"Time zone abbreviation": "+10",
		"Source file": "backward",
		Notes: "Link to Pacific/Port_Moresby",
	},
	{
		"Country code(s)": "CL",
		"TZ identifier": "Pacific/Easter",
		"Embedded comments": "Easter Island",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "-06",
		"Source file": "-05",
		Notes: "southamerica",
	},
	{
		"Country code(s)": "VU",
		"TZ identifier": "Pacific/Efate",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+11:00",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "+11",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "KI",
		"TZ identifier": "Pacific/Enderbury",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+13:00",
		"UTC offset±hh:mm DST": "+13:00",
		"Time zone abbreviation": "+13",
		"Source file": "backward",
		Notes: "Link to Pacific/Kanton",
	},
	{
		"Country code(s)": "TK",
		"TZ identifier": "Pacific/Fakaofo",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+13:00",
		"UTC offset±hh:mm DST": "+13:00",
		"Time zone abbreviation": "+13",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "FJ",
		"TZ identifier": "Pacific/Fiji",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+12:00",
		"UTC offset±hh:mm DST": "+12:00",
		"Time zone abbreviation": "+12",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "TV",
		"TZ identifier": "Pacific/Funafuti",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+12:00",
		"UTC offset±hh:mm DST": "+12:00",
		"Time zone abbreviation": "+12",
		"Source file": "backward",
		Notes: "Link to Pacific/Tarawa",
	},
	{
		"Country code(s)": "EC",
		"TZ identifier": "Pacific/Galapagos",
		"Embedded comments": "Galápagos Islands",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "-06",
		"Source file": "southamerica",
		Notes: "",
	},
	{
		"Country code(s)": "PF",
		"TZ identifier": "Pacific/Gambier",
		"Embedded comments": "Gambier Islands",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-09:00",
		"UTC offset±hh:mm DST": "-09:00",
		"Time zone abbreviation": "-09",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "SB, FM",
		"TZ identifier": "Pacific/Guadalcanal",
		"Embedded comments": "Pohnpei",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+11:00",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "+11",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "GU, MP",
		"TZ identifier": "Pacific/Guam",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+10:00",
		"Time zone abbreviation": "ChST",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "Pacific/Honolulu",
		"Embedded comments": "Hawaii",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-10:00",
		"UTC offset±hh:mm DST": "-10:00",
		"Time zone abbreviation": "HST",
		"Source file": "northamerica",
		Notes: "",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "Pacific/Johnston",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-10:00",
		"UTC offset±hh:mm DST": "-10:00",
		"Time zone abbreviation": "HST",
		"Source file": "backward",
		Notes: "Link to Pacific/Honolulu",
	},
	{
		"Country code(s)": "KI",
		"TZ identifier": "Pacific/Kanton",
		"Embedded comments": "Phoenix Islands",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+13:00",
		"UTC offset±hh:mm DST": "+13:00",
		"Time zone abbreviation": "+13",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "KI",
		"TZ identifier": "Pacific/Kiritimati",
		"Embedded comments": "Line Islands",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+14:00",
		"UTC offset±hh:mm DST": "+14:00",
		"Time zone abbreviation": "+14",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "FM",
		"TZ identifier": "Pacific/Kosrae",
		"Embedded comments": "Kosrae",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+11:00",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "+11",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "MH",
		"TZ identifier": "Pacific/Kwajalein",
		"Embedded comments": "Kwajalein",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+12:00",
		"UTC offset±hh:mm DST": "+12:00",
		"Time zone abbreviation": "+12",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "MH",
		"TZ identifier": "Pacific/Majuro",
		"Embedded comments": "most of Marshall Islands",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+12:00",
		"UTC offset±hh:mm DST": "+12:00",
		"Time zone abbreviation": "+12",
		"Source file": "backward",
		Notes: "Link to Pacific/Tarawa",
	},
	{
		"Country code(s)": "PF",
		"TZ identifier": "Pacific/Marquesas",
		"Embedded comments": "Marquesas Islands",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-09:30",
		"UTC offset±hh:mm DST": "-09:30",
		"Time zone abbreviation": "-0930",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "UM",
		"TZ identifier": "Pacific/Midway",
		"Embedded comments": "Midway Islands",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "-11:00",
		"UTC offset±hh:mm DST": "-11:00",
		"Time zone abbreviation": "SST",
		"Source file": "backward",
		Notes: "Link to Pacific/Pago_Pago",
	},
	{
		"Country code(s)": "NR",
		"TZ identifier": "Pacific/Nauru",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+12:00",
		"UTC offset±hh:mm DST": "+12:00",
		"Time zone abbreviation": "+12",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "NU",
		"TZ identifier": "Pacific/Niue",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-11:00",
		"UTC offset±hh:mm DST": "-11:00",
		"Time zone abbreviation": -11,
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "NF",
		"TZ identifier": "Pacific/Norfolk",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+11:00",
		"UTC offset±hh:mm DST": "+12:00",
		"Time zone abbreviation": "+11",
		"Source file": "+12",
		Notes: "australasia",
	},
	{
		"Country code(s)": "NC",
		"TZ identifier": "Pacific/Noumea",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+11:00",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "+11",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "AS, UM",
		"TZ identifier": "Pacific/Pago_Pago",
		"Embedded comments": "Midway",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-11:00",
		"UTC offset±hh:mm DST": "-11:00",
		"Time zone abbreviation": "SST",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "PW",
		"TZ identifier": "Pacific/Palau",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+09:00",
		"UTC offset±hh:mm DST": "+09:00",
		"Time zone abbreviation": "+09",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "PN",
		"TZ identifier": "Pacific/Pitcairn",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-08:00",
		"UTC offset±hh:mm DST": "-08:00",
		"Time zone abbreviation": "-08",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "FM",
		"TZ identifier": "Pacific/Pohnpei",
		"Embedded comments": "Pohnpei/Ponape",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+11:00",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "+11",
		"Source file": "backward",
		Notes: "Link to Pacific/Guadalcanal",
	},
	{
		"Country code(s)": "FM",
		"TZ identifier": "Pacific/Ponape",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+11:00",
		"UTC offset±hh:mm DST": "+11:00",
		"Time zone abbreviation": "+11",
		"Source file": "backward",
		Notes: "Link to Pacific/Guadalcanal",
	},
	{
		"Country code(s)": "PG, AQ, FM",
		"TZ identifier": "Pacific/Port_Moresby",
		"Embedded comments":
			"Papua New Guinea (most areas), Chuuk, Yap, Dumont d'Urville",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+10:00",
		"Time zone abbreviation": "+10",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "CK",
		"TZ identifier": "Pacific/Rarotonga",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-10:00",
		"UTC offset±hh:mm DST": "-10:00",
		"Time zone abbreviation": -10,
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "MP",
		"TZ identifier": "Pacific/Saipan",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+10:00",
		"Time zone abbreviation": "ChST",
		"Source file": "backward",
		Notes: "Link to Pacific/Guam",
	},
	{
		"Country code(s)": "AS",
		"TZ identifier": "Pacific/Samoa",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-11:00",
		"UTC offset±hh:mm DST": "-11:00",
		"Time zone abbreviation": "SST",
		"Source file": "backward",
		Notes: "Link to Pacific/Pago_Pago",
	},
	{
		"Country code(s)": "PF",
		"TZ identifier": "Pacific/Tahiti",
		"Embedded comments": "Society Islands",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-10:00",
		"UTC offset±hh:mm DST": "-10:00",
		"Time zone abbreviation": -10,
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "KI, MH, TV, UM, WF",
		"TZ identifier": "Pacific/Tarawa",
		"Embedded comments": "Gilberts, Marshalls, Wake",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+12:00",
		"UTC offset±hh:mm DST": "+12:00",
		"Time zone abbreviation": "+12",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "TO",
		"TZ identifier": "Pacific/Tongatapu",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+13:00",
		"UTC offset±hh:mm DST": "+13:00",
		"Time zone abbreviation": "+13",
		"Source file": "australasia",
		Notes: "",
	},
	{
		"Country code(s)": "FM",
		"TZ identifier": "Pacific/Truk",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+10:00",
		"Time zone abbreviation": "+10",
		"Source file": "backward",
		Notes: "Link to Pacific/Port_Moresby",
	},
	{
		"Country code(s)": "UM",
		"TZ identifier": "Pacific/Wake",
		"Embedded comments": "Wake Island",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+12:00",
		"UTC offset±hh:mm DST": "+12:00",
		"Time zone abbreviation": "+12",
		"Source file": "backward",
		Notes: "Link to Pacific/Tarawa",
	},
	{
		"Country code(s)": "WF",
		"TZ identifier": "Pacific/Wallis",
		"Embedded comments": "",
		Type: "Link†",
		"UTC offset±hh:mm SDT": "+12:00",
		"UTC offset±hh:mm DST": "+12:00",
		"Time zone abbreviation": "+12",
		"Source file": "backward",
		Notes: "Link to Pacific/Tarawa",
	},
	{
		"Country code(s)": "FM",
		"TZ identifier": "Pacific/Yap",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+10:00",
		"UTC offset±hh:mm DST": "+10:00",
		"Time zone abbreviation": "+10",
		"Source file": "backward",
		Notes: "Link to Pacific/Port_Moresby",
	},
	{
		"Country code(s)": "PL",
		"TZ identifier": "Poland",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+01:00",
		"UTC offset±hh:mm DST": "+02:00",
		"Time zone abbreviation": "CET",
		"Source file": "CEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "PT",
		"TZ identifier": "Portugal",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "WET",
		"Source file": "WEST",
		Notes: "backward",
	},
	{
		"Country code(s)": "CN",
		"TZ identifier": "PRC",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "CST",
		"Source file": "backward",
		Notes: "Link to Asia/Shanghai",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "PST8PDT",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "-08:00",
		"UTC offset±hh:mm DST": "-07:00",
		"Time zone abbreviation": "PST",
		"Source file": "PDT",
		Notes: "northamerica",
	},
	{
		"Country code(s)": "TW",
		"TZ identifier": "ROC",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "CST",
		"Source file": "backward",
		Notes: "Link to Asia/Taipei",
	},
	{
		"Country code(s)": "KR",
		"TZ identifier": "ROK",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+09:00",
		"UTC offset±hh:mm DST": "+09:00",
		"Time zone abbreviation": "KST",
		"Source file": "backward",
		Notes: "Link to Asia/Seoul",
	},
	{
		"Country code(s)": "SG",
		"TZ identifier": "Singapore",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+08:00",
		"UTC offset±hh:mm DST": "+08:00",
		"Time zone abbreviation": "+08",
		"Source file": "backward",
		Notes: "Link to Asia/Singapore",
	},
	{
		"Country code(s)": "TR",
		"TZ identifier": "Turkey",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "+03",
		"Source file": "backward",
		Notes: "Link to Europe/Istanbul",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "UCT",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "UTC",
		"Source file": "backward",
		Notes: "Link to Etc/UTC",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Universal",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "UTC",
		"Source file": "backward",
		Notes: "Link to Etc/UTC",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "US/Alaska",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-09:00",
		"UTC offset±hh:mm DST": "-08:00",
		"Time zone abbreviation": "AKST",
		"Source file": "AKDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "US/Aleutian",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-10:00",
		"UTC offset±hh:mm DST": "-09:00",
		"Time zone abbreviation": "HST",
		"Source file": "HDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "US/Arizona",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-07:00",
		"Time zone abbreviation": "MST",
		"Source file": "backward",
		Notes: "Link to America/Phoenix",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "US/Central",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "CST",
		"Source file": "CDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "US/East-Indiana",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "US/Eastern",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "US/Hawaii",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-10:00",
		"UTC offset±hh:mm DST": "-10:00",
		"Time zone abbreviation": "HST",
		"Source file": "backward",
		Notes: "Link to Pacific/Honolulu",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "US/Indiana-Starke",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-06:00",
		"UTC offset±hh:mm DST": "-05:00",
		"Time zone abbreviation": "CST",
		"Source file": "CDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "US/Michigan",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-05:00",
		"UTC offset±hh:mm DST": "-04:00",
		"Time zone abbreviation": "EST",
		"Source file": "EDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "US/Mountain",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-07:00",
		"UTC offset±hh:mm DST": "-06:00",
		"Time zone abbreviation": "MST",
		"Source file": "MDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "US",
		"TZ identifier": "US/Pacific",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-08:00",
		"UTC offset±hh:mm DST": "-07:00",
		"Time zone abbreviation": "PST",
		"Source file": "PDT",
		Notes: "backward",
	},
	{
		"Country code(s)": "AS",
		"TZ identifier": "US/Samoa",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "-11:00",
		"UTC offset±hh:mm DST": "-11:00",
		"Time zone abbreviation": "SST",
		"Source file": "backward",
		Notes: "Link to Pacific/Pago_Pago",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "UTC",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "UTC",
		"Source file": "backward",
		Notes: "Link to Etc/UTC",
	},
	{
		"Country code(s)": "RU",
		"TZ identifier": "W-SU",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+03:00",
		"UTC offset±hh:mm DST": "+03:00",
		"Time zone abbreviation": "MSK",
		"Source file": "backward",
		Notes: "Link to Europe/Moscow",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "WET",
		"Embedded comments": "",
		Type: "Canonical",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+01:00",
		"Time zone abbreviation": "WET",
		"Source file": "WEST",
		Notes: "europe",
	},
	{
		"Country code(s)": "",
		"TZ identifier": "Zulu",
		"Embedded comments": "",
		Type: "Link",
		"UTC offset±hh:mm SDT": "+00:00",
		"UTC offset±hh:mm DST": "+00:00",
		"Time zone abbreviation": "UTC",
		"Source file": "backward",
		Notes: "Link to Etc/UTC",
	},
];
